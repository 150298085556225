import { Box } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { EnumRadioButton } from "../../../../../../../components/inputs/radio-button-input/EnumRadioButton"
import {
  BSItemContext,
  dtoToForm,
  formToDto,
  IForm,
} from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMacroComponentType } from "../../../../../../../core/dto/beem-shot/BSMacroComponent/BSMacroComponentType"
import { CalculTypeEnum, calculTypeEnumLabelsOption } from "../../../../../../../core/enum/beem-shot/CalculTypeEnum"
import { useBSItem } from "../../../../../../../core/hooks/beem-shot/useBSItem"
import { useForm } from "../../../../../../../core/hooks/form/use-form"
import { MacroComponentSelect } from "./MacroComponentSelect"

interface IProps {
  bsItem: BSItem
  disabled: boolean
}

export function BSMacroComponentCard({ bsItem, disabled }: Readonly<IProps>): React.JSX.Element {
  const { bsProjectId } = useParams()

  const { updateBSItemFunction } = useContext(BSItemContext)

  const { getBSMacroComponentTypeList } = useBSItem()

  const [bsMacroComponentTypes, setBSMacroComponentTypes] = useState<BSMacroComponentType[]>([])

  useEffect(() => {
    if (bsProjectId) {
      getBSMacroComponentTypeList(bsItem.type).then((response) => {
        setBSMacroComponentTypes(response)
      })
    }
  }, [bsItem.type, bsProjectId, getBSMacroComponentTypeList])

  const submit = useCallback((someForm: IForm) => updateBSItemFunction(formToDto(someForm)), [updateBSItemFunction])

  const { form, setForm } = useForm(bsItem, dtoToForm, [], submit)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateBSItemFunction(formToDto(form, e.target.value))
      setForm({ ...form, calculType: e.target.value as CalculTypeEnum })
    },
    [form, setForm, updateBSItemFunction]
  )

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {bsItem.type.name !== "CFO" && bsItem.type.name !== "CFA" && (
        <EnumRadioButton
          id="calculType"
          label="Données environnementales utilisées"
          value={form.calculType}
          options={calculTypeEnumLabelsOption}
          handleChange={handleChange}
          flexDirection="column"
          alignItems="flex-start"
          disabled={disabled}
        />
      )}

      {bsMacroComponentTypes.map((bsMacroComponentType) => (
        <MacroComponentSelect
          key={bsMacroComponentType.name}
          bsMacroComponentType={bsMacroComponentType}
          bsItem={bsItem}
          disabled={disabled}
        />
      ))}
    </Box>
  )
}
