import { Paper, Tooltip } from "@mui/material"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { customPalette } from "../../../../../../../../theme"
import { BSItemContext } from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { BSMacroComponentType } from "../../../../../../../core/dto/beem-shot/BSMacroComponent/BSMacroComponentType"
import { useBSItem } from "../../../../../../../core/hooks/beem-shot/useBSItem"
import { CodeDisplayer } from "../../../../../components/BSBimModelExtractedCode/CodeDisplayer"

interface IProps {
  bsItem: BSItem
  disabled: boolean
}

export function BSItemPaper({ bsItem, disabled }: Readonly<IProps>): React.JSX.Element {
  const { bsProjectId } = useParams()

  const { selectedBSItem, setSelectedBSItem } = useContext(BSItemContext)

  const { getBSMacroComponentTypeList } = useBSItem()

  const [bsMacroComponentTypes, setBSMacroComponentTypes] = useState<BSMacroComponentType[]>([])

  useEffect(() => {
    if (bsProjectId) {
      getBSMacroComponentTypeList(bsItem.type).then((response) => {
        setBSMacroComponentTypes(response)
      })
    }
  }, [bsItem.type, bsProjectId, getBSMacroComponentTypeList])

  const selectBSItem = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSelectedBSItem(bsItem)
    },
    [bsItem, setSelectedBSItem]
  )

  return (
    <Tooltip arrow placement="right" title={disabled ? "Veuillez activer cet élément avant de le modifier." : ""}>
      <Paper
        elevation={0}
        square={false}
        onClick={selectBSItem}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "10px",
          border:
            selectedBSItem?.id === bsItem.id
              ? `2px solid ${customPalette.mainGreen5}`
              : `2px solid ${customPalette.paperBorder}`,
          backgroundColor: customPalette.paperBackground,
          width: "100%",
          p: "5px",
        }}>
        <CodeDisplayer
          codeTitle={bsItem.type.label}
          codeDescription={bsItem.codeExtrait?.descriptionFromBimModel}
          codeExtrait={bsItem.codeExtrait}
          codeColor={bsItem.codeExtrait?.color ?? "red"}
          showBorder={false}
        />
      </Paper>
    </Tooltip>
  )
}
