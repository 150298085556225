import InfoIcon from "@mui/icons-material/Info"
import { Box, Icon, Tab, Tabs, Typography } from "@mui/material"
import React from "react"

interface IProps {
  selectedTab: number
  progress: number

  handleChange(event: React.SyntheticEvent, newValue: number): void
}

export function QuestionnairesTitle({ selectedTab, handleChange, progress }: Readonly<IProps>): React.JSX.Element {
  return (
    <>
      <Box id="Title" display="flex" justifyContent="center" width="100%">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          TabIndicatorProps={{ style: { display: "none" } }}
          textColor="inherit"
          sx={{
            my: 3,
            backgroundColor: "#ffffff",
            borderRadius: 2,
            height: "1vh",
            boxShadow: 1,
            cursor: progress !== 100 ? "wait" : "pointer",
          }}>
          <Tab
            label="Informations simplifiées"
            sx={{
              backgroundColor: selectedTab === 0 ? "#E6F0FB" : "",
              borderRadius: "15px",
              height: { lg: "0.5vh", xl: "1vh" },
              color: selectedTab === 0 ? "#40548D" : "",
              cursor: progress !== 100 ? "wait" : "pointer",
              fontSize: { lg: 12, xl: 14 },
              maxWidth: {
                lg: selectedTab === 1 ? "125px" : "100%",
                xl: "100%",
              },
            }}
          />
          <Tab
            label="Informations avancées"
            sx={{
              backgroundColor: selectedTab === 1 ? "#E6F0FB" : "",
              borderRadius: "15px",
              height: "1vh",
              color: selectedTab === 1 ? "#40548D" : "",
              cursor: progress !== 100 ? "wait" : "pointer",
              fontSize: { lg: 12, xl: 14 },
              maxWidth: {
                lg: selectedTab === 1 ? "125px" : "100%",
                xl: "100%",
              },
            }}
          />
        </Tabs>
      </Box>
      <Box display="flex" justifyContent="center" gap={1} sx={{ pb: 2 }}>
        <Icon>
          <InfoIcon
            sx={{
              fontSize: { lg: 20, xl: 24 },
            }}
          />
        </Icon>
        <Typography
          variant="body2"
          sx={{
            fontSize: { lg: 12, xl: 14 },
            textAlign: "justify",
          }}>
          Ces informations sont chargées par la maquette numérique. Nous vous invitons à les vérifier et les modifier au
          besoin pour calculer l'impact carbone.
        </Typography>
      </Box>
    </>
  )
}
