import React from "react"
import { customPalette } from "../../../../theme"
import { NumberInput } from "./NumberInput"

interface IProps {
  id: string
  label: string
  value: number | undefined
  formBackgroundColor?: typeof customPalette.textPrimaryWhite | typeof customPalette.paperBackground
  disabled?: boolean
  handleChange?(value: string): void
  handleFocusOut?(event: React.FocusEvent<HTMLSelectElement | HTMLInputElement>): void
}

export function FilledNumberInput({
  id,
  label,
  value,
  formBackgroundColor = customPalette.textPrimaryWhite,
  disabled = false,
  handleChange,
  handleFocusOut,
}: Readonly<IProps>): React.JSX.Element {
  return (
    <NumberInput
      id={id}
      label={label}
      value={value}
      variant="filled"
      borderRadius={3}
      backgroundColor={
        formBackgroundColor === customPalette.textPrimaryWhite
          ? customPalette.paperBackground
          : customPalette.textPrimaryWhite
      }
      disabled={disabled}
      handleChange={handleChange}
      handleFocusOut={handleFocusOut}
      disableUnderline
      sx={{
        "&.Mui-focused": {
          backgroundColor:
            formBackgroundColor === customPalette.textPrimaryWhite
              ? customPalette.paperBackground
              : customPalette.textPrimaryWhite,
        },
      }}
    />
  )
}
