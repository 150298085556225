import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { customPalette } from "../../../../theme"

export type SelectOption<T> = {
  id?: string
  value: T
  label: string | undefined
}

type DefaultOption = SelectOption<"">

interface IProps<T> {
  id: string // it must be a key of the form
  label: string
  selectedOption: T | undefined
  options: SelectOption<T>[]
  isBeemShot?: boolean
  mode?: "direct" | "event"
  disabled?: boolean
  errors?: Record<string, string | undefined>
  size?: "small" | "medium"
  backgroundColor?: string
  nullable?: boolean
  endAdornment?: React.JSX.Element | undefined
  borderRadius?: number
  hideBorder?: boolean
  margin?: "none" | "dense" | "normal"

  handleChange?(selectedValue: T): void

  handleEventChange?(event: ChangeEvent<HTMLInputElement>): void

  handleSubmit?(): void
}

export function BaseSelectInput<T>({
  id,
  label,
  selectedOption,
  options,
  handleChange,
  mode = "direct",
  handleEventChange,
  disabled = false,
  errors = {},
  isBeemShot = false,
  size = "medium",
  handleSubmit,
  backgroundColor,
  nullable = false,
  endAdornment,
  borderRadius = 0,
  hideBorder = false,
  margin = "none",
}: Readonly<IProps<T>>): React.JSX.Element {
  const [isOptionsEmpty, setIsOptionsEmpty] = useState<boolean>(false)

  const isInitialized = useRef<boolean>(false)

  const setValue = useCallback(
    (value: T | undefined): void => {
      if (!value) {
        return
      }

      if (mode === "direct" && handleChange) {
        handleChange(value)
      } else if (mode === "event" && handleEventChange) {
        // It's when handleChange expects ChangeEvent
        const e = {
          target: {
            id,
            value,
          },
        } as unknown as ChangeEvent<HTMLInputElement>
        handleEventChange(e)
      }
    },
    [handleChange, handleEventChange, id, mode]
  )

  const initSelectedOption = useCallback((): T | "" => {
    if (nullable && !selectedOption) {
      return ""
    }
    if (selectedOption) {
      return selectedOption
    } else if (options?.length > 0 && options[0]) {
      setValue(options[0].value)
      return options[0].value
    } else {
      return ""
    }
  }, [selectedOption, options, setValue])

  const initOptions: () => SelectOption<T>[] | DefaultOption[] = useCallback(() => {
    const newOption = initSelectedOption()
    setActualSelected(newOption)

    if (options === undefined || options.length === 0) {
      setIsOptionsEmpty(true)
      return [{ value: "", label: "" }]
    } else {
      setIsOptionsEmpty(false)
      return [...options]
    }
  }, [initSelectedOption, options])

  const initSelected = useMemo(() => initSelectedOption(), [initSelectedOption, selectedOption])
  const [actualSelected, setActualSelected] = useState<T | "">(initSelected)
  const actualOptions = useMemo<SelectOption<T>[] | DefaultOption[]>(() => initOptions(), [initOptions])

  function handleChangeLocal(event: SelectChangeEvent): void {
    const newSelectedValue = deserializeValue(event.target.value)
    setActualSelected(newSelectedValue)
    if (!isOptionsEmpty) {
      setValue(newSelectedValue as T)
    }
  }

  function serializeValue(value: T | ""): string {
    return String(value)
  }

  // Converts string back to original value
  function deserializeValue(value: string): T | "" {
    return options.find((option) => String(option.value) === value)?.value ?? ""
  }

  useEffect(() => {
    if (handleSubmit && isInitialized.current) {
      handleSubmit()
    }
  }, [initSelected])

  function authorizeToUpdate(): void {
    isInitialized.current = true
  }

  return (
    <FormControl
      fullWidth
      error={!!errors[id]}
      margin={margin}
      sx={{
        backgroundColor: isBeemShot ? customPalette.textPrimaryWhite : backgroundColor,
        borderRadius: borderRadius || 0,

        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: hideBorder ? "none" : undefined,
            borderRadius: borderRadius || 0,
          },
          "&:hover fieldset": {
            border: hideBorder ? "none" : undefined,
            borderRadius: borderRadius || 0,
          },
          "&.Mui-focused fieldset": {
            border: hideBorder ? "none" : undefined,
            borderRadius: borderRadius || 0,
          },
        },
      }}>
      <InputLabel sx={{ fontSize: { lg: 14, xl: 16 } }}>{label}</InputLabel>
      <Select
        id={id}
        aria-describedby={id}
        disabled={disabled}
        IconComponent={disabled ? "p" : undefined}
        labelId={`${label}-id-label`}
        value={serializeValue(actualSelected)}
        onChange={handleChangeLocal}
        label={label}
        size={size}
        error={!!errors[id]}
        endAdornment={endAdornment}
        sx={{ fontSize: { lg: 14, xl: 16 } }}
        MenuProps={{
          PaperProps: {
            sx: {
              borderRadius: borderRadius || 0,
              elevation: 2, // Réduit l'élévation
              boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
            },
          },
        }}>
        {actualOptions.map((option) => (
          <MenuItem
            key={option.label}
            value={serializeValue(option.value)}
            onClick={() => authorizeToUpdate()}
            sx={{ fontSize: { lg: 14, xl: 16 } }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText error id={id}>
        {errors[id]}
      </FormHelperText>
    </FormControl>
  )
}
