import { Box, Typography } from "@mui/material"
import React, { useContext } from "react"
import { customPalette } from "../../../../../../../../theme"
import { theme } from "../../../../../../../components/layout/BSLayout"
import { BSItemContext } from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItemQuantityCard } from "./BSItemQuantityCard"
import { BSMacroComponentCard } from "./BSMacroComponentCard"

export function AdvancedQuestionaryMaterialPanel(): React.JSX.Element {
  const { selectedBSItem } = useContext(BSItemContext)

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      flexShrink={1}
      border={2}
      borderRadius={3}
      borderColor={customPalette.paperBorder}
      gap={2}
      my={3}
      sx={{
        backgroundColor: customPalette.paperBackground,
        height: "70vh",
        overflow: "auto",
        p: 3,
      }}>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: 2,
          }}>
          <Typography variant="subtitle2" color={theme.palette.info.main}>
            Quantités extraites de la maquette
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              height: "2px",
              backgroundColor: customPalette.lightblue,
              marginLeft: "8px",
            }}
          />
        </Box>
        {selectedBSItem ? (
          <BSItemQuantityCard bsItem={selectedBSItem} disabled={selectedBSItem.ignored} />
        ) : (
          <Box
            sx={{
              borderRadius: 3,
              backgroundColor: customPalette.textPrimaryWhite,
              border: `2px solid ${customPalette.paperBorder}`,
              p: 4,
            }}>
            <Typography sx={{ textAlign: "center", fontSize: { lg: 12, xl: 14 } }}>
              Veuillez sélectionner un macro composant pour voir ou modifier ses quantités.
            </Typography>
          </Box>
        )}
      </Box>

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            mb: 1,
          }}>
          <Typography variant="subtitle2" color={theme.palette.info.main}>
            Matérialités
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              height: "2px",
              backgroundColor: customPalette.lightblue,
              marginLeft: "8px",
            }}
          />
        </Box>
        {selectedBSItem ? (
          <BSMacroComponentCard bsItem={selectedBSItem} disabled={selectedBSItem.ignored} />
        ) : (
          <Box
            sx={{
              borderRadius: 3,
              backgroundColor: customPalette.textPrimaryWhite,
              border: `2px solid ${customPalette.paperBorder}`,
              p: 4,
            }}>
            <Typography sx={{ textAlign: "center", fontSize: { lg: 12, xl: 14 } }}>
              Veuillez sélectionner un macro composant pour voir ou modifier ses matérialités.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
