import { InputAdornment, SxProps, TextField, TextFieldProps, Theme } from "@mui/material"
import { FilledInputProps } from "@mui/material/FilledInput"
import { InputProps as StandardInputProps } from "@mui/material/Input/Input"
import { OutlinedInputProps } from "@mui/material/OutlinedInput"
import React, { ChangeEvent, useMemo } from "react"
import { NumericFormat } from "react-number-format"
import { NumberFormatValues } from "react-number-format/types/types"
import { customPalette } from "../../../../theme"

interface IProps {
  id: string
  label: string
  value: number | undefined
  disabled?: boolean
  allowEmptyInput?: boolean
  variant?: "standard" | "filled" | "outlined"
  size?: "small" | "medium"
  decimalScale?: number
  unit?: string
  fullWidth?: boolean
  shrink?: boolean
  backgroundColor?: string
  errors?: Record<string, string | undefined>
  mode?: "direct" | "event" // if "direct", use handleChange. Else if "event", use handleEventChange
  hideBorder?: boolean
  borderRadius?: number
  disableUnderline?: boolean
  sx?: SxProps<Theme>

  handleChange?(value: string): void

  handleEventChange?(event: ChangeEvent<HTMLInputElement>): void

  handleFocusOut?(event: React.FocusEvent<HTMLSelectElement | HTMLInputElement>): void
}

export function NumberInput({
  id,
  value,
  label,
  disabled,
  allowEmptyInput = false,
  variant = "standard",
  size = undefined,
  decimalScale = 2,
  unit,
  fullWidth = true,
  shrink,
  backgroundColor,
  errors,
  mode = "direct",
  hideBorder = false,
  disableUnderline = false,
  handleChange,
  handleEventChange,
  handleFocusOut,
  borderRadius = 0,
}: Readonly<IProps>): React.JSX.Element {
  const materialUITextFieldProps = {
    id,
    label,
    variant,
    fullWidth,
    disabled,
    size,
    error: errors ? !!errors[id] : false,
    helperText: errors ? errors[id] : "",
  }

  function internalHandleChange(values: NumberFormatValues): void {
    const result = values.value !== "" ? values.value : "0"
    if (mode === "direct" && handleChange) {
      handleChange(result)
    } else if (handleEventChange) {
      handleEventChange({ target: { id, value: result, type: "number" } } as ChangeEvent<HTMLInputElement>)
    }
  }

  const computeInputProps:
    | Partial<StandardInputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined = useMemo(() => {
    let style: Partial<StandardInputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps> | undefined = {}

    if (unit) {
      style = { ...style, endAdornment: <InputAdornment position="end">{unit}</InputAdornment> }
    }

    style = { ...style, sx: { borderRadius } }

    if (backgroundColor) {
      style = {
        ...style,
        sx: {
          ...(style.sx || {}),
          backgroundColor,
          "&:hover": { backgroundColor },
          "&.Mui-focused": { backgroundColor },
          "&.Mui-disabled": {
            backgroundColor: customPalette.lightGray1,
          },
        },
      }
    }

    if (hideBorder) {
      style = {
        ...style,
        sx: {
          ...(style.sx || {}),
          "& fieldset": {
            border: "none",
          },
        },
      }
    }

    if (disableUnderline && (variant === "standard" || variant === "filled")) {
      style = { ...style, disableUnderline: true }
    }

    return Object.keys(style).length > 0 ? style : undefined
  }, [backgroundColor, borderRadius, disableUnderline, hideBorder, unit, variant])

  return (
    <NumericFormat
      onBlur={handleFocusOut}
      value={value}
      customInput={NumberTextField}
      allowNegative={false}
      decimalScale={decimalScale}
      decimalSeparator=","
      onValueChange={internalHandleChange}
      allowedDecimalSeparators={[".", ","]}
      thousandSeparator=" "
      InputProps={computeInputProps}
      InputLabelProps={{ shrink }}
      allowEmptyInput={allowEmptyInput}
      {...materialUITextFieldProps}
    />
  )
}

type NumberTextFieldProps = TextFieldProps & {
  allowEmptyInput: boolean
}

function NumberTextField(props: NumberTextFieldProps): React.JSX.Element {
  const { value, label, allowEmptyInput, ...otherProps } = props
  let actualValue: string
  if (!allowEmptyInput && value !== undefined && value !== "" && typeof value === "string") {
    actualValue = value
  } else {
    actualValue = "0"
  }

  return (
    <TextField
      {...otherProps}
      label={label}
      value={actualValue}
      sx={{
        "& .MuiInputLabel-root": {
          fontSize: { lg: 14, xl: 16 },
        },
        "& .MuiInputBase-input": {
          fontSize: { lg: 14, xl: 16 },
        },
      }}
    />
  )
}
