import { Grid } from "@mui/material"
import React, { FormEvent, useCallback, useContext } from "react"
import { customPalette } from "../../../../../../../../theme"
import { FilledNumberInput } from "../../../../../../../components/inputs/number-input/FilledNumberInput"
import {
  BSItemContext,
  dtoToForm,
  formToDto,
  IForm,
} from "../../../../../../../core/context/beem-shot/BSItems/BSItemContext"
import { BSItem } from "../../../../../../../core/dto/beem-shot/BSItem/BSItem"
import { CodeExtrait } from "../../../../../../../core/dto/code-extrait/code-extrait"
import { Quantity } from "../../../../../../../core/dto/quantity"
import { useForm } from "../../../../../../../core/hooks/form/use-form"
import { identity } from "../../../../../../../core/services/helper-service"

interface IProps {
  bsItem: BSItem
  disabled: boolean
}

export function BSItemQuantityCard({ bsItem, disabled }: Readonly<IProps>): React.JSX.Element {
  const { updateBSItemFunction } = useContext(BSItemContext)

  const submit = useCallback((someForm: IForm) => updateBSItemFunction(formToDto(someForm)), [updateBSItemFunction])

  const { form, handleSubmit, setForm } = useForm(bsItem, dtoToForm, [], submit)

  function handleFocusOut(): void {
    const e = { preventDefault: identity } as FormEvent<HTMLFormElement> | FormEvent<HTMLDivElement>
    handleSubmit(e, false)
  }

  function handleChangeNumber(fieldName: keyof Quantity, newAmount: string): void {
    handleChangeCoreData({ target: { name: fieldName, value: newAmount } })
  }

  function handleChangeCoreData(e: { target: { name: string; value: string } }): void {
    const fieldName = e.target.name

    if (form.codeExtrait) {
      const value: number = parseFloat(e.target.value)
      const newQuantity: Quantity = { ...form.codeExtrait?.overiddenQuantities, [fieldName]: value }
      const newCodeExtrait: CodeExtrait = { ...form.codeExtrait, overiddenQuantities: newQuantity }

      setForm({ ...form, codeExtrait: newCodeExtrait })
    }
  }

  return (
    <Grid container rowSpacing={1} columnSpacing={1}>
      <Grid item xs={6}>
        <FilledNumberInput
          id="number"
          label="Nombre"
          value={form.codeExtrait?.overiddenQuantities?.number ?? 0}
          formBackgroundColor={customPalette.paperBackground}
          handleChange={(value) => {
            handleChangeNumber("number", value)
          }}
          handleFocusOut={handleFocusOut}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <FilledNumberInput
          id="length"
          label="Longueur (m)"
          value={form.codeExtrait?.overiddenQuantities.length ?? 0}
          formBackgroundColor={customPalette.paperBackground}
          handleChange={(value) => {
            handleChangeNumber("length", value)
          }}
          handleFocusOut={handleFocusOut}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <FilledNumberInput
          id="height"
          label="Hauteur (m)"
          value={form.codeExtrait?.overiddenQuantities.height ?? 0}
          formBackgroundColor={customPalette.paperBackground}
          handleChange={(value) => {
            handleChangeNumber("height", value)
          }}
          handleFocusOut={handleFocusOut}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6}>
        <FilledNumberInput
          id="surface"
          label="Surface (m²)"
          value={form.codeExtrait?.overiddenQuantities.surface ?? 0}
          formBackgroundColor={customPalette.paperBackground}
          handleChange={(value) => {
            handleChangeNumber("surface", value)
          }}
          handleFocusOut={handleFocusOut}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
