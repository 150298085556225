import React, { ChangeEvent } from "react"
import { customPalette } from "../../../../theme"
import { BaseSelectInput, SelectOption } from "./BaseSelectInput"

interface IProps<T> {
  id: string // it must be a key of the form
  label: string
  selectedOption: T | undefined
  options: SelectOption<T>[]
  mode?: "direct" | "event"
  isBeemShot?: boolean
  errors?: Record<string, string | undefined>
  nullable?: boolean
  formBackgroundColor?: typeof customPalette.textPrimaryWhite | typeof customPalette.paperBackground
  disabled?: boolean
  handleEventChange?(event: ChangeEvent<HTMLInputElement>): void
  handleChange?(selectedValue: T): void
  handleSubmit?(): void
}

export function FormBaseSelectInput<T>({
  id,
  label,
  selectedOption,
  options,
  formBackgroundColor = customPalette.textPrimaryWhite,
  handleEventChange,
  handleSubmit,
  handleChange,
  errors = {},
  nullable = false,
  disabled = false,
}: Readonly<IProps<T>>): React.JSX.Element {
  return (
    <BaseSelectInput
      id={id}
      label={label}
      selectedOption={selectedOption}
      options={options}
      backgroundColor={
        formBackgroundColor === customPalette.textPrimaryWhite
          ? customPalette.paperBackground
          : customPalette.textPrimaryWhite
      }
      borderRadius={3}
      hideBorder
      margin="normal"
      mode="direct"
      handleEventChange={handleEventChange}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      errors={errors}
      nullable={nullable}
      disabled={disabled}
    />
  )
}
