import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined"
import { Box, Grid, Tooltip, Typography } from "@mui/material"
import React from "react"
import { customPalette } from "../../../../../theme"
import { CodeExtraitDisplay } from "../../../../core/dto/code-extrait/CodeExtraitDisplay"

type iProps = {
  codeExtrait?: CodeExtraitDisplay
  codeTitle: string
  codeDescription?: string
  codeColor: string
  highlightBorder?: boolean
  handleClickAcv?: any
  numberElements?: number
  showBorder?: boolean
}

export function CodeDisplayer({
  codeExtrait,
  codeTitle,
  codeDescription,
  codeColor,
  highlightBorder,
  handleClickAcv,
  numberElements,
  showBorder = true,
}: Readonly<iProps>): React.JSX.Element {
  const codeOccurrence: string = codeExtrait?.occurence ? `(${codeExtrait.code}${codeExtrait?.occurence})` : ""

  return (
    <Box width="100%" sx={{ cursor: "pointer" }}>
      <Grid
        container
        onClick={handleClickAcv}
        sx={{
          px: showBorder ? 1 : 0,
          py: showBorder ? 0.5 : 0,
          border: showBorder ? 4 : 0,
          borderRadius: 2,
          borderColor: highlightBorder ? "#0BE2A0" : "#ECF3F3",
          alignItems: "center",
          backgroundColor: customPalette.paperBackground,
        }}>
        <Grid item xs={1} display="flex">
          {codeExtrait ? (
            <Box sx={{ background: codeColor, width: { lg: "5px", xl: "10px" }, height: "36px", borderRadius: "7px" }} />
          ) : (
            <Tooltip title="Valeur calculée" arrow placement="top">
              <CalculateOutlinedIcon sx={{ fontSize: { lg: 20, xl: 24 } }} />
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={11} display="flex" flexDirection="column">
          <Typography variant="body2" fontWeight={600} sx={{ fontSize: { lg: 12, xl: 14 } }}>
            {`${codeTitle} ${codeOccurrence}`}
          </Typography>
          <Typography variant="body2" sx={{ color: "#8398A6", fontSize: { lg: 12, xl: 14 } }}>
            {codeDescription}
          </Typography>
        </Grid>
      </Grid>

      {numberElements && (
        <Box
          sx={{
            ml: "auto",
            background: "black",
            borderRadius: 2,
            color: "white",
            px: 1,
          }}>
          {numberElements}
        </Box>
      )}
    </Box>
  )
}
